import imageOne from "@data/images/team/team-member-07-370x250.jpg";
import imageTwo from "@data/images/team/team-member-01-370x250.jpg";
import imageThree from "@data/images/team/team-member-06-370x250.jpg";
import imageFour from "@data/images/team/team-member-04-370x250.jpg";

export const data = [
	{
		id: "dllie-horton",
		name: "Dollie Horton",
		designation: "Chief People Officer",
		position: "head",
		image: imageOne,
		socials: {
			facebook: "https://www.facebook.com",
			twitter: "https://www.twitter.com",
			instagram: "https://www.instagram.com"
		}
	},
	{
		id: "robert-amplilian",
		name: "Robert Amplilian",
		designation: "Chief People Officer",
		position: "head",
		image: imageTwo,
		socials: {
			facebook: "https://www.facebook.com",
			twitter: "https://www.twitter.com",
			instagram: "https://www.instagram.com"
		},
		is_featured: false
	},
	{
		id: "stephen-mearsley",
		name: "Stephen Mearsley",
		designation: "President & CEO",
		position: "head",
		image: imageThree,
		socials: {
			facebook: "https://www.facebook.com",
			twitter: "https://www.twitter.com",
			instagram: "https://www.instagram.com"
		}
	},
	{
		id: "daisy-hortron",
		name: "Daisy Hortron",
		designation: "Financial Services",
		position: "executive",
		image: imageFour,
		socials: {
			facebook: "https://www.facebook.com",
			twitter: "https://www.twitter.com",
			instagram: "https://www.instagram.com"
		},
		is_featured: false
	},
]