import imageOne from "@data/images/team/team-member-07-370x455.jpg";
import imageTwo from "@data/images/team/team-member-01-370x455.jpg";
import imageThree from "@data/images/team/team-member-06-370x455.jpg";
import imageFour from "@data/images/team/team-member-04-370x250.jpg";
import imageFive from "@data/images/team/team-member-02-370x250.jpg";
import imageSix from "@data/images/team/team-member-05-370x250.jpg";
import imageSeven from "@data/images/team/team-member-03-370x250.jpg";

export const headMembers = [
	{
		id: "dllie-horton",
		name: "Dollie Horton",
		designation: "Chief People Officer",
		position: "head",
		image: imageOne,
		socials: {
			facebook: "https://www.facebook.com",
			twitter: "https://www.twitter.com",
			instagram: "https://www.instagram.com"
		}
	},
	{
		id: "robert-amplilian",
		name: "Robert Amplilian",
		designation: "Chief People Officer",
		position: "head",
		image: imageTwo,
		socials: {
			facebook: "https://www.facebook.com",
			twitter: "https://www.twitter.com",
			instagram: "https://www.instagram.com"
		}
	},
	{
		id: "stephen-mearsley",
		name: "Stephen Mearsley",
		designation: "President & CEO",
		position: "head",
		image: imageThree,
		socials: {
			facebook: "https://www.facebook.com",
			twitter: "https://www.twitter.com",
			instagram: "https://www.instagram.com"
		}
	}
]

export const executives = [
	{
		id: "daisy-hortron",
		name: "Daisy Hortron",
		designation: "Financial Services",
		position: "executive",
		image: imageFour,
		socials: {
			facebook: "https://www.facebook.com",
			twitter: "https://www.twitter.com",
			instagram: "https://www.instagram.com"
		}
	},
	{
		id: "maggie-strickland",
		name: "Maggie Strickland",
		designation: "HR executive",
		position: "executive",
		image: imageFive,
		socials: {
			facebook: "https://www.facebook.com",
			twitter: "https://www.twitter.com",
			instagram: "https://www.instagram.com"
		}
	},
	{
		id: "monica-blews",
		name: "Monica Blews",
		designation: "Project manager",
		position: "executive",
		image: imageSix,
		socials: {
			facebook: "https://www.facebook.com",
			twitter: "https://www.twitter.com",
			instagram: "https://www.instagram.com"
		}
	},
	{
		id: "issac-blews",
		name: "Issac Blews",
		designation: "Marketing Executive",
		position: "executive",
		image: imageSeven,
		socials: {
			facebook: "https://www.facebook.com",
			twitter: "https://www.twitter.com",
			instagram: "https://www.instagram.com"
		}
	},
]